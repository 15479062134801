import { SliceComponentProps } from '@prismicio/react'
import React from 'react'
import Button from '../Button'
import RichText from '../RichText'
import AppContainer from '../AppContainer'
import { GridCol, GridRow } from '../grid'
import styled from 'styled-components'

interface ISlice {
  primary: {
    content: {
      richText: any
    }
    bottom_arrow_label?: string
    bottom_arrow_link?: any
    link_color?: string
  }
}

const SimpleText = (props: SliceComponentProps) => {
  const slice = props.slice as unknown as ISlice
  return (
    <>
      <AppContainer withMobilePadding={true}>
        <StyledGridRow>
          <GridCol l={4} m={1} s={0}></GridCol>
          <GridCol l={8} m={6} s={4}>
            <RichText
              field={slice.primary.content.richText}
              color={slice.primary.link_color}
            ></RichText>
            {slice.primary.bottom_arrow_label && (
              <Button type="small">{slice.primary.bottom_arrow_label}</Button>
            )}
          </GridCol>
          <GridCol l={4} m={1} s={0}></GridCol>
        </StyledGridRow>
      </AppContainer>
    </>
  )
}

const StyledGridRow = styled(GridRow)`
  margin: 80px 0;
`

export default SimpleText
